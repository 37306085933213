export interface IMetricsConfigDataModel {
  defaultLabel: string;
  defaultRule?: IFundLimitDataModel;
  limitRules: IFundLimitDataModel[];
}

export interface IFundLimitDataModel {
  fundId: number;
  value: number;
  overLabel: string;
  sortOrder: number;
}

export function createMetricsConfigDataModel(
  overrides: Partial<IMetricsConfigDataModel> = {}
): IMetricsConfigDataModel {
  return {
    defaultLabel: '',
    limitRules: [],
    ...overrides,
  };
}

export function createFundLimitDataModel(overrides: Partial<IFundLimitDataModel> = {}): IFundLimitDataModel {
  return {
    fundId: 0,
    overLabel: '',
    value: 0,
    sortOrder: 0,
    ...overrides,
  };
}
