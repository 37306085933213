import {
  createMetricsConfigDataModel,
  IFundLimitDataModel,
} from '../../data-models/metrics-config.data-model';
import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import {
  realizedAmountWithEscrow,
  realizedValueAdjusted,
} from '../../pages/PortfolioOverview/components/OverviewTable/MetricsFormulas';

const otherCompanyIds = new Set([54117, 475]);
const otherFundIds = new Set([1, 3, 4, 5, 13, 18, 22]);

const fundLimitConfig = createMetricsConfigDataModel({
  defaultLabel: 'Seed',
  defaultRule: { fundId: -1, overLabel: 'Core', value: 1000000, sortOrder: 0 },
  limitRules: [
    { fundId: 12, overLabel: 'Core', value: 3000000, sortOrder: 0 },
    { fundId: 6, overLabel: 'Core', value: 500000, sortOrder: 1 },
    { fundId: 7, overLabel: 'Core', value: 500000, sortOrder: 2 },
    { fundId: 8, overLabel: 'Core', value: 500000, sortOrder: 3 },
  ],
});

export function customLabelsCalculator(metric: IMetricsDataModel, includedFundsIds: Set<number>): string {
  const config = fundLimitConfig;
  let label;
  let ruleMatched = false;

  if (otherCompanyIds.has(metric.companyId)) {
    return 'Other';
  }

  if (includedFundsIds.size > 0 && includedFundsIds.intersection(otherFundIds).size > 0) {
    // Set to other but still let the fund limit rules override.
    label = 'Other';
  }

  const limitsByFundId = config.limitRules.reduce((res, limit) => {
    return res.set(limit.fundId, limit);
  }, new Map<number, IFundLimitDataModel>());

  if (config.limitRules.length > 0) {
    limitsByFundId.forEach((limitRule) => {
      if (includedFundsIds.has(limitRule.fundId)) {
        ruleMatched = true;
        const amountInvestedValue = metric.fundData.find((f) => {
          return f.fundId === limitRule.fundId;
        })?.amountInvested;
        if (amountInvestedValue && amountInvestedValue > limitRule.value) {
          label = limitRule.overLabel;
        }
      }
    });
  }

  // Only try the default rule is no other were matched.
  if (!ruleMatched && !label && config.defaultRule) {
    const { overLabel, value } = config.defaultRule;
    label = metric.fundData.some((f) => {
      return f.amountInvested && f.amountInvested > value;
    })
      ? overLabel
      : undefined;
  }

  return label ?? config.defaultLabel;
}

export function realizedMOICWithEscrow(metrics: IMetricsDataModel) {
  const realizedValue = realizedValueAdjusted(metrics);
  const _realizedAmountWithEscrow = realizedAmountWithEscrow(metrics);

  return realizedValue > 0 ? Number((_realizedAmountWithEscrow / realizedValue).toFixed(2)) : 0;
}

export function realizedGLWithEscrow(metrics: IMetricsDataModel) {
  return metrics.realizedAmountWithEscrow - realizedValueAdjusted(metrics);
}

export const gcFormulas = {
  customLabel: customLabelsCalculator,
};
