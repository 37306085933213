import { IMetricsDataModel } from '../../data-models/metrics.data-model';
import {
  afterProcessTransactions as afterProcessTransactionsBase,
  createMetricTotalProcessor,
  createTransactionsProcessor,
} from '../default/metrics';
import { IAfterProcessTransactionsContext, ITransactionProcessResult } from '../default/metrics.type';
import { gcFormulas, realizedGLWithEscrow, realizedMOICWithEscrow } from './metric-formulas';

function afterProcessTransactions(
  metric: IMetricsDataModel & ITransactionProcessResult,
  context: IAfterProcessTransactionsContext
) {
  const res = afterProcessTransactionsBase(metric, context);
  const includedFundsIds = new Set(Array.from(metric.includedFunds.keys()));

  return {
    ...res,
    realizedGLWithEscrow: realizedGLWithEscrow(metric),
    realizedMOICWithEscrow: realizedMOICWithEscrow(metric),
    seedCore: gcFormulas.customLabel(metric, includedFundsIds),
  };
}

export { createTransactionsProcessor, createMetricTotalProcessor, afterProcessTransactions };
